
import HintTooltip from '@/components/helper/HintTooltip.vue'
import JigTextField from '@/components/input/JigTextField.vue'
import ChipList from '@/components/material/ChipList.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import ShareModal from '@/components/modals/ShareModal.vue'
import ShareSuccessSnackbar from '@/components/share/ShareSuccessSnackbar.vue'
import StatLineChart from '@/components/statistics/StatLineChart.vue'
import MaxJigsCheck from '@/mixin/MaxJigsCheck'
import { hasThumbnailPlaceHolder } from '@/plugins/filters'
import {
  Tenant,
  TenantToken,
  TenantUser,
} from '@/store/modules/app/types'
import { AppConst } from '@/store/modules/constants'
import {
  ChangeJigOwnerRequest,
  ChangeJigOwnerResult,
  ChangeOwnerTenantRequest,
  JigCategoryOption,
  JigMetaData,
  JigShareURLData,
  Permission,
} from '@/store/modules/jig/types'
import {
  ImageValidationResult,
  JigActivityChartData,
  Namespace,
  ReportJigSummaryStats,
  ReportRequest,
  StandardObject,
  UploadThumbnailRequest,
} from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { DateFormatter } from '@/utils/date-formatter'
import { ValidationRules } from '@/utils/input-validation'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import 'flatpickr/dist/flatpickr.css'
import * as FlatPickr from 'vue-flatpickr-component'
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { Action, Mutation, State } from 'vuex-class'

const LookBackDays = 28
const ComparePreviousPeriod = false

const defaultJigViewTrendChart: JigActivityChartData = {
  data: {
    labels: [],
    datasets: [
      {
        label: 'views',
        backgroundColor: '#66c2a5',
        data: [],
      },
      {
        label: 'avg duration',
        backgroundColor: '#e78ac3',
        data: [],
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 1,
      },
    },
    title: {
      display: true,
      text: 'Jig Views',
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
    },
    scales: {
      xAxes: [{
        ticks: {
          autoSkip: true,
          autoSkipPadding: 15,
          maxRotation: 0,
        },
      }],
      yAxes: [{
        ticks: {
          min: 0,
        },
      }],
    },
  },
}

@Component({
  components: {
    StatLineChart,
    'prompt-yes-no': PromptYesNo,
    'chip-list': ChipList,
    'prompt-share-modal': ShareModal,
    'jig-text-field': JigTextField,
    'flat-pickr': FlatPickr,
    'share-success-snackbar': ShareSuccessSnackbar,
    'hint-tooltip': HintTooltip,
  },
})

export default class JigDetailVue extends Mixins(MaxJigsCheck) {
  @State('jigDailyViewsData', { namespace: Namespace.Jig })
  public jigDailyViewsData!: ReportJigSummaryStats[]
  @State('isStatisticsLoading', { namespace: Namespace.Jig })
  public isStatisticsLoading!: boolean
  @State('jigMetadata', { namespace: Namespace.Jig })
  public jigMetadata!: JigMetaData
  @State('jigDetailPermissions', { namespace: Namespace.Jig })
  public jigDetailPermissions!: Permission[]
  @State('jigCategoryOptions', { namespace: Namespace.Jig })
  public jigCategoryOptions!: JigCategoryOption[]
  @State('changeJigOwnerResult', { namespace: Namespace.Jig })
  public changeJigOwnerResult!: ChangeJigOwnerResult
  @State('myTenantTokens', { namespace: Namespace.App })
  public myTenantTokens!: TenantToken[]
  @State('tenantUsers', { namespace: Namespace.App })
  public tenantUsers?: TenantUser[]
  @State('loading', { namespace: Namespace.Utils })
  public loading!: boolean

  @Mutation('changeLoadingState', { namespace: Namespace.Utils })
  public changeLoadingState: any

  @Action('executeStatisticReport', { namespace: Namespace.Jig }) public executeStatisticReport: any
  @Action('loadJigMetadata', { namespace: Namespace.Jig }) public loadJigMetadata: any
  @Action('loadJigPermissions', { namespace: Namespace.Jig }) public loadJigPermissions: any
  @Action('deleteJigById', { namespace: Namespace.Jig }) public deleteJigById: any
  @Action('copyJigById', { namespace: Namespace.Jig }) public copyJigById: any
  @Action('clearJigShareLink', { namespace: Namespace.Jig }) public clearJigShareLink: any
  @Action('updateJigMetadata', { namespace: Namespace.Jig }) public updateJigMetadata: any
  @Action('updateJigVisibility', { namespace: Namespace.Jig }) public updateJigVisibility: any
  @Action('changeJigOwner', { namespace: Namespace.Jig }) public changeJigOwner: any
  @Action('changeJigOwnerTenant', { namespace: Namespace.Jig }) public changeJigOwnerTenant: any
  @Action('uploadJigThumbnail', { namespace: Namespace.Jig }) public uploadJigThumbnail: any
  @Action('createJigPermission', { namespace: Namespace.Jig }) public createJigPermission: any
  @Action('updateJigPermission', { namespace: Namespace.Jig }) public updateJigPermission: any
  @Action('deleteJigPermission', { namespace: Namespace.Jig }) public deleteJigPermission: any
  @Action('requestJigDeeplinkURL', { namespace: Namespace.Jig }) public requestJigDeeplinkURL: any

  public $refs!: Vue['$refs'] & {
    deleteJigPromptYesNo: PromptYesNo,
    copyJigPromptYesNo: PromptYesNo,
    jigLimitPromptYesNo: PromptYesNo,
    publishJigPromptYesNo: PromptYesNo,
    freeToProJigPromptYesNo: PromptYesNo,
    publicToPasswordJigPromptYesNo: PromptYesNo,
    linkToPrivatePromptYesNo: PromptYesNo,
    privateToLinkPromptYesNo: PromptYesNo,
    passwordRegeneratePromptYesNo: PromptYesNo,
    transferJigPromptYesNo: PromptYesNo,
    changeJigOwnerTenantPromptYesNo: PromptYesNo,
    saveJigErrorPrompt: PromptYesNo,
    tagsChipList: ChipList,
    sharePrompt: ShareModal,
    pullApartHint: any,
  }
  public newCopyJigName!: string
  public nameRules !: any
  public emailRules !: any
  public tagsRules !: any
  public visibilityItems !: any
  public categoryItems: any = []
  public name: any = 'JigDetail'
  public isFormValid: boolean = false
  public transferJigPromptIsValid: boolean = false
  public changeJigOwnerTenantPromptIsValid: boolean = false
  public visibilityChangedToFromPublicButNotPrompted: boolean = false
  public isPublicSharingPromptPending: boolean = false
  public visibilityAlreadyPublic: boolean = false
  public visibilityPublic: string = AppConst.JigVisibilities.Public

  private desiredOwnerEmail: string = ''
  private originalJigMetaData!: JigMetaData

  private jigThumbnailToUpload!: File
  private jigThumbnailToUploadArrayBuffer: any = ''
  private isShowUploadThumb: boolean = false
  private isUploadingThumbnail: boolean = false
  private isThumbFileSelected: boolean = false
  private isThumbnailUploadValid: boolean = false
  private uploadRules !: any
  private uploadExternalValidationErrors: string[] = []
  private readonlyVue: boolean = true
  private visibilityOnLoad: string = ''
  private freeJigTurningPrivatePromptPending: boolean = false
  private freeJigTurningLinkPasswordPromptPending: boolean = false
  private jigPasswordRegeneratePromptPending: boolean = false
  private permissionsDirty: boolean = false
  private publicSharing: boolean = false
  private jigViewTrendChart: JigActivityChartData = defaultJigViewTrendChart
  private isFetchingJigData: boolean = false
  private teamSharePermissions: string[] = []
  private teamSharePermissionsReshareValue: string = AppConst.SharePermission.teamPermissionReshare
  private isCopyableByOthers: boolean = false
  private copyableBy: string = ''
  private copyableByListItems: StandardObject[] = AppConst.copyableByList

  private pullApartAllowed: boolean = false

  private clipboardSnackbarVisible: boolean = false
  private latestClipboardEntry: string = ''
  private isClipboardCopySuccess: boolean = false
  private isLoadingJigDetails: boolean = true

  private totalJigViews: number = 0

  private myTenantID: number = -1
  private myTenantIndex: number = 0
  private transferToTenantID: number = -1
  private numberRules: any = null

  private thumbnailUploadError: any = {
    isActive: false,
    copy: AppConst.nonSystemErrors.unprocessableEntity.copy,
  }
  private saveJigError: string = ''

  private get isOwner(): boolean {
    return this.jigMetadata.Uid === this.$auth0.uid
  }

  private get isCopyableByOwnerTenant(): boolean {
    return this.myTenant.ID === this.jigMetadata.OwnerTenantID && this.jigMetadata.CopyableBy === AppConst.JigCopyable.Tenant
  }

  private get isCopyableByAnyone(): boolean {
    return this.jigMetadata.CopyableBy === AppConst.JigCopyable.Anyone
  }

  private ownerTenantName(): string {
    const ownerTenant = this.myTenantTokens.find(token => token.id === this.jigMetadata.OwnerTenantID)

    return ownerTenant ? ownerTenant.name : ''
  }

  private get currentOwnerTeam(): TenantUser | undefined | null {
    return this.tenantUsers ? this.tenantUsers.find((user: TenantUser) => user.email === this.$auth0.user.email) : null
  }

  private get isChangeOwnerTeamAllowed(): boolean {
    return TenantHelpers.IsChangeOwnerTeamAllowed(this.currentOwnerTeam, this.myTenant.ID, this.jigMetadata.OwnerTenantID)
  }

  private get isTeamShared(): boolean {
    // if the RequestUserPermission.ID == -1 or 0, and the RequestUserPermission.CRUD is null or RequestUserPermission.CRUD.R == true then the Jig was shared via team access settings.
    const RUP = this.jigMetadata.RequestUserPermission

    return RUP && RUP.ID <= 0 && (Object.keys(RUP.CRUD).length === 0 || RUP.CRUD.R === true)
  }

  private get teamCanReshare(): boolean {
    return this.teamSharePermissions.includes(this.teamSharePermissionsReshareValue)
  }

  private get canEditJig(): boolean {
    const jig = this.jigMetadata

    if (jig === null || !jig.RequestUserPermission || jig.RequestUserPermission.CRUD === null) {
      return false
    }

    return jig.RequestUserPermission.CRUD.U === true && !this.readonlyVue
  }

  private get eventTracking(): any {
    return {
      action: 'JigDetail',
    }
  }

  private get isSharingDisabled(): boolean {
    return (this.readonlyVue && !this.teamCanReshare || !this.isOwner) && !this.canEditJig && !this.teamCanReshare
  }

  private get showJigActions(): boolean {
    return this.isOwner || this.canEditJig || this.teamCanReshare
  }

  private get canCopy(): boolean {
    // Jig can be duplicated if:
    // 1. user is Jig owner
    // 2. Jig is set to CopyableByAnyone
    // 3. Jig is set to CopyableByTeam and user is part of the team
    // 4. Jig is set to Copyable by Team/Anyone and user is a superuser
    return this.isOwner || this.isCopyableByAnyone || (this.jigMetadata.OwnerTenantID === this.myTenant.ID && this.isCopyableByOwnerTenant) || (TenantHelpers.IsSuperUser(this.myTenantTokens) && this.jigMetadata.CopyableBy !== AppConst.JigCopyable.Owner)
  }

  private get isPullApartConfigAllowed(): boolean {
    return this.jigMetadata.FormatVersionAtCreation > 8.8
  }

  public async deleteJig() {
    await this.deleteJigById({ jigId: this.jigMetadata.Id, jigFormatVersion: this.jigMetadata.FormatVersion })
    segmentEventTracking(
      'JigDeleted',
      {
        ...this.eventTracking,
        jigId: this.jigMetadata.Id,
        jigName: this.jigMetadata.ProjectName,
      }
    )
    this.$router.push({ path: '/my-jigs' })
  }

  protected async created() {
    // default jig view activity date range to 30 days ago until yesterday
    let today = new Date()

    this.startDateJigViewActivity.setDate(today.getDate() - 31)
    this.finishDateJigViewActivity.setDate(today.getDate() - 1)

    this.nameRules = ValidationRules.RequiredName
    this.emailRules = ValidationRules.RequiredEmail
    this.tagsRules = ValidationRules.OptionalTags
    this.uploadRules = ValidationRules.PngImageUpload
    this.numberRules = ValidationRules.RequiredNumber

    this.updateCopyableByList()

    if (this.isSuperUserOrJigStaff) {
      this.visibilityItems = [
        {
          text: 'Public',
          value: AppConst.JigVisibilities.Public,
        },
        {
          text: 'Private',
          value: AppConst.JigVisibilities.Private,
        },
        {
          text: 'Unlisted',
          value: AppConst.JigVisibilities.Link,
        },
        {
          text: 'Password protected',
          value: AppConst.JigVisibilities.Password,
        },
      ]
    } else {
      this.visibilityItems = [
        {
          text: 'Private',
          value: AppConst.JigVisibilities.Private,
        },
        {
          text: 'Unlisted',
          value: AppConst.JigVisibilities.Link,
        },
        {
          text: 'Password protected',
          value: AppConst.JigVisibilities.Password,
        },
      ]
    }
    await this.loadJigAndReset()
  }

  // get jig view data after the jig details have been loaded.
  @Watch('jigMetadata')
  private async onJigDetailChanged(value: JigMetaData, oldValue: JigMetaData) {
    if (typeof (this.jigMetadata) === 'undefined') {
      return
    }

    this.transferToTenantID = this.jigMetadata.OwnerTenantID || -1
    this.pullApartAllowed = this.jigMetadata.Features != null && this.jigMetadata.Features.options[AppConst.Features.AVPFeaturePullApartKey].enabled

    await this.loadJigViewStatisticData()
  }

  @Watch('myTenant')
  private onMyTenantChanged(value: Tenant) {
    this.myTenantID = value.ID
    this.updateMyTenantIndex()
    this.updateCopyableByList()
  }

  @Watch('jigDailyViewsData')
  private onJigDailyViewsChanged(value: number, oldValue: number) {
    this.updateJigViewsChart()
  }

  private get getJigViewsChart(): JigActivityChartData {
    this.updateJigViewsChart()
    return this.jigViewTrendChart
  }

  private flatPickrConfigSingle: object = {
    // dateFormat: "d.m.Y",
    maxDate: 'today',
    mode: 'single',
    defaultDate: [(new Date).setDate(-1)],
  }

  private startDateJigViewActivity: Date = new Date

  private finishDateJigViewActivity: Date = new Date

  private isCopyableByItemRestricted(value: string) {
    return this.myTenant.Subscription.RestrictedJigCopyableByTypes && this.myTenant.Subscription.RestrictedJigCopyableByTypes.includes(value)
  }

  private updateCopyableByList() {
    this.copyableByListItems.forEach((copyableItem: StandardObject) => {
      if (this.isCopyableByItemRestricted(copyableItem.value as string)) {
        copyableItem.disabled = true
      } else {
        copyableItem.disabled = false
      }
    })
  }

  private inviteSucceeded(payload: any) {
    segmentEventTracking(
      'JigShared',
      {
        ...this.eventTracking,
        editorEmail: payload.editorEmail,
        viewersEmails: payload.viewersEmails.join(','),
        jigId: this.jigMetadata.Id,
        jigName: this.jigMetadata.ProjectName,
        jigOwner: `${this.jigMetadata.Author}${this.isSuperUserOrJigStaff ? ' (JigStaff)' : ''}`,
        jigThumbail: this.jigMetadata.ThumbnailURL.indexOf('jig-placeholder-color') > -1 ? '' : this.jigMetadata.ThumbnailURL,
        jigUrl: this.jigMetadata.DeeplinkURL,
        jigVisibility: this.jigMetadata.ProjectVisibility,
        isJigOwner: this.isOwner,
        teamShared: this.isTeamShared,
        sharingActivity: 'InviteUserToViewOrEdit',
      }
    )
  }

  private showShareSuccessMsg(payload: any) {
    this.isClipboardCopySuccess = payload.isClipboardCopySuccess
    this.latestClipboardEntry = payload.latestClipboardEntry
    this.clipboardSnackbarVisible = payload.clipboardSnackbarVisible
  }

  private snackbarClose(payload: boolean) {
    this.clipboardSnackbarVisible = payload
    // Clear custom success message so that when shareable link message can be displayed properly.
  }

  // support manual fetch rather than automatic on page opening fetching
  private fetchJigViewDataClick() {
    this.loadJigViewStatisticData()
  }

  private async loadJigViewStatisticData() {
    // The UI date picker can set the date values to just simple date strings of format "YYYY/MM/DD".
    // This isn't compatible with the format the API expects. So we create new dates and then get the
    // ISO formats out of them.
    let startDate = new Date(this.startDateJigViewActivity)
    let endDate = new Date(this.finishDateJigViewActivity)
    const req: ReportRequest = {
      Name: AppConst.Report.TenantDashboard,
      JigHashId: this.jigMetadata.Id,
      Limit: 1,
      LookBackDays,
      StartDate: startDate.toISOString(),
      EndDate: endDate.toISOString(),
      ComparePreviousPeriod,
    }

    this.isFetchingJigData = true
    await this.executeStatisticReport(req)
    this.isFetchingJigData = false
  }

  private async updateJigViewsChart() {
    if (!this.jigDailyViewsData) {
      return
    }

    this.jigViewTrendChart.data.datasets.length = 0
    this.jigViewTrendChart.data.labels.length = 0

    const labels: string[] = []

    // for each Jig capture a series of date (day) view totals.
    const jigViewDailyTotalsSeriesData: Map<string, number> = new Map<string, number>()

    let flagDate: string = ''
    let total = 0
    // iterate of the series of days of statistic data
    for (let i = 0; i < this.jigDailyViewsData.length; i++) {
      const item: ReportJigSummaryStats = this.jigDailyViewsData[i]
      const dateObject = new Date(item.statisticDate)
      let newData = true

      if (flagDate === item.statisticDate && item.dataAvailable && item.activity === AppConst.ReportActivity.ViewingJig) {
        total += item.total
        newData = false
      } else if (flagDate === item.statisticDate && !(item.dataAvailable && item.activity === AppConst.ReportActivity.ViewingJig)) {
        continue
      } else if (i === 0 || flagDate !== item.statisticDate) {
        total = item.dataAvailable && item.activity === AppConst.ReportActivity.ViewingJig ? item.total : 0
      }

      jigViewDailyTotalsSeriesData.set(item.statisticDate, total)

      if (newData) {
        // create month + date labels
        const monthString = DateFormatter.getMonthString(dateObject, true)
        const dateString = dateObject.getDate()

        labels.push(`${monthString} ${dateString}`)
      }

      flagDate = item.statisticDate
      if (flagDate !== item.statisticDate) {
        total = 0
      }
    }

    this.jigViewTrendChart.data.labels = labels

    const viewsChartData = [...jigViewDailyTotalsSeriesData.values()]

    this.totalJigViews = viewsChartData.reduce((accumulator, current) => accumulator + current, 0)

    this.jigViewTrendChart.data.datasets.push(
      {
        label: "Views",
        borderColor: "#00D8FF",
        pointBackgroundColor: "white",
        borderWidth: 2,
        pointBorderColor: "#00D8FF",
        data: viewsChartData,
      },
    )
  }

  private async loadJigAndReset() {
    this.isLoadingJigDetails = true
    this.readonlyVue = true
    this.newCopyJigName = ''

    await this.loadJigMetadata({
      jigId: this.$route.params.id,
    })
    await this.loadJigPermissions({
      jigId: this.$route.params.id,
    })
    for (const p of this.jigDetailPermissions) {
      if (p.CRUD.R && p.CRUD.U && p.AudID === this.$auth0.uid) {
        this.readonlyVue = false
        break
      }
    }

    this.publicSharing = this.jigMetadata.ProjectVisibility !== AppConst.JigVisibilities.Private
    this.visibilityAlreadyPublic = this.jigMetadata.ProjectVisibility === AppConst.JigVisibilities.Public
    this.visibilityOnLoad = this.jigMetadata.ProjectVisibility
    this.addNonStandardCategoriesWithWarning(this.jigMetadata.Categories)
    this.originalJigMetaData = Object.assign({}, this.jigMetadata)
    this.myTenantID = this.myTenant.ID
    this.updateMyTenantIndex()
    this.pullApartAllowed = this.jigMetadata.Features != null && this.jigMetadata.Features.options[AppConst.Features.AVPFeaturePullApartKey].enabled

    if (this.jigMetadata.Tenants) {
      // When the permissions are removed completely, Permissions might return null so we need to assign empty array as fallback
      this.teamSharePermissions = this.jigMetadata.Tenants[this.myTenantIndex] && this.jigMetadata.Tenants[this.myTenantIndex].Permissions ? this.jigMetadata.Tenants[this.myTenantIndex].Permissions : []
    }

    if (this.jigMetadata.CopyableBy === AppConst.JigCopyable.Owner) {
      this.isCopyableByOthers = false
      this.copyableBy = AppConst.JigCopyable.Anyone // default
    } else if (this.jigMetadata.CopyableBy) {
      this.isCopyableByOthers = true
      this.copyableBy = this.jigMetadata.CopyableBy
    }

    this.isLoadingJigDetails = false
  }

  private updateMyTenantIndex() {
    // Jig details endpoint returns full list of Team Access permissions (fixed in EN-549), for easier access we need to record the index of matching Tenant's team access settings. Otherwise we will have to use Array.find all the time.
    if (!this.jigMetadata.Tenants || this.jigMetadata.Tenants.length === 0) {
      return undefined
    }

    let matchingIndex = this.jigMetadata.Tenants.findIndex((tenant) => tenant.TenantID === this.myTenantID && tenant.Type === 'sharing')

    // If current Tenant's team access permission already exists, save its index for easier access. Otherwise index should indicate the new index that new permission item can be assigned to in `.Tenants` array.
    if (matchingIndex >= 0) {
      this.myTenantIndex = matchingIndex
    } else {
      this.myTenantIndex = this.jigMetadata.Tenants.length
    }
  }

  private addNonStandardCategoriesWithWarning(categories: string[]): any {
    this.categoryItems = this.jigCategoryOptions
    const nonStandardCategories = categories.filter((x: string) =>
      !this.jigCategoryOptions.some((y: JigCategoryOption) => y.value === x))
    this.categoryItems = this.categoryItems.concat(nonStandardCategories.map((z: string) => ({
      text: `${z} (not standard)`,
      value: z,
    })))
  }

  private showDeleteJigPrompt() {
    this.$refs.deleteJigPromptYesNo.ShowPrompt()
  }

  private async copyJig() {
    await this.copyJigById({
      jigId: this.jigMetadata.Id,
      newName: this.newCopyJigName,
      tenantId: this.myTenantID,
    })
    segmentEventTracking(
      'JigCopied',
      {
        ...this.eventTracking,
        jigId: this.jigMetadata.Id,
        jigName: this.newCopyJigName,
        sourceJigName: this.jigMetadata.ProjectName,
      }
    )
    this.$router.push({ path: '/my-jigs' })
  }

  private async showCopyJigPrompt() {
    const isJigLimitReached = await this.isMaxJigsLimitReached()

    if (isJigLimitReached) {
      segmentEventTracking('TenantMaxJigLimit_FeatureGateShown', {
        tenantName: this.myTenant.Name,
        tenantId: this.myTenantID,
      })
      this.$refs.jigLimitPromptYesNo.ShowPrompt()
    } else {
      this.newCopyJigName = `${this.jigMetadata.ProjectName} copy`
      this.$refs.copyJigPromptYesNo.ShowPrompt()
    }
  }

  private checkAboutToPublish(option: any) {
    this.publicSharing = option !== AppConst.JigVisibilities.Private
    this.freeJigTurningPrivatePromptPending = false
    this.freeJigTurningLinkPasswordPromptPending = false
    this.jigPasswordRegeneratePromptPending = false

    if (this.visibilityOnLoad !== AppConst.JigVisibilities.Public) {
      if (option === AppConst.JigVisibilities.Public) {
        this.visibilityChangedToFromPublicButNotPrompted = true
      } else {
        this.visibilityChangedToFromPublicButNotPrompted = false

        if (this.visibilityOnLoad === AppConst.JigVisibilities.Link && option === AppConst.JigVisibilities.Private) {
          this.freeJigTurningPrivatePromptPending = true
        } else if (this.visibilityOnLoad === AppConst.JigVisibilities.Link && option === AppConst.JigVisibilities.Password) {
          this.freeJigTurningLinkPasswordPromptPending = true
        } else if (this.visibilityOnLoad === AppConst.JigVisibilities.Private && option === AppConst.JigVisibilities.Password && this.jigMetadata.Password) {
          this.jigPasswordRegeneratePromptPending = true
        }
      }
    } else {
      this.visibilityChangedToFromPublicButNotPrompted = true
    }
  }

  private onPublicSharingChanged() {
    this.isPublicSharingPromptPending = true
  }

  private checkingPublicSharingPublish(): boolean {
    if (this.visibilityOnLoad === AppConst.JigVisibilities.Private || this.visibilityOnLoad === AppConst.JigVisibilities.Password) {
      if (this.publicSharing) {
        this.$refs.privateToLinkPromptYesNo.ShowPrompt()
        return true
      }
    } else if (this.visibilityOnLoad === AppConst.JigVisibilities.Link) {
      if (!this.publicSharing) {
        this.$refs.linkToPrivatePromptYesNo.ShowPrompt()
        return true
      }
    }
    return false
  }

  private async publishCheckedThenSaveChanges() {
    this.visibilityChangedToFromPublicButNotPrompted = false
    await this.saveChanges()
  }

  private async changeJigToPrivateConfirmed() {
    this.publicSharing = false
    this.jigMetadata.ProjectVisibility = AppConst.JigVisibilities.Private
    this.freeJigTurningPrivatePromptPending = false

    await this.saveChanges()
  }

  private async regeneratePasswordConfirmed(regeneratePassword: boolean) {
    this.publicSharing = false
    this.jigMetadata.ProjectVisibility = AppConst.JigVisibilities.Password
    this.jigPasswordRegeneratePromptPending = false

    await this.saveChanges(regeneratePassword)
  }

  private async changeJigToLinkPasswordConfirmed() {
    this.freeJigTurningLinkPasswordPromptPending = false
    if (!!this.jigMetadata.Password) {
      this.$refs.passwordRegeneratePromptYesNo.ShowPrompt()
    } else {
      this.regeneratePasswordConfirmed(false)
    }
  }

  private async changeJigToLinkConfirmed() {
    this.publicSharing = true
    this.jigMetadata.ProjectVisibility = AppConst.JigVisibilities.Link
    await this.saveChanges()
  }

  private async saveChanges(regeneratePassword: boolean | null | undefined = null) {
    const queryParams: any = {}
    let telemetryRequired = false

    if (this.visibilityChangedToFromPublicButNotPrompted) {
      this.$refs.publishJigPromptYesNo.ShowPrompt()
      return
    }

    if (this.freeJigTurningPrivatePromptPending) {
      this.$refs.freeToProJigPromptYesNo.ShowPrompt()
      return
    }

    if (this.freeJigTurningLinkPasswordPromptPending) {
      this.$refs.publicToPasswordJigPromptYesNo.ShowPrompt()
      return
    } else if (this.jigPasswordRegeneratePromptPending) {
      this.$refs.passwordRegeneratePromptYesNo.ShowPrompt()
      return
    }

    if (!this.publicSharingSwitchDisabled && this.isPublicSharingPromptPending) {
      this.isPublicSharingPromptPending = false
      if (this.checkingPublicSharingPublish()) {
        return
      }
    }

    this.jigMetadata.ProjectName = AppHelper.stringSanitiser(this.jigMetadata.ProjectName)
    this.jigMetadata.ProjectDescription = AppHelper.stringSanitiser(this.jigMetadata.ProjectDescription)
    if (this.$refs.tagsChipList && this.$refs.tagsChipList.editedItems) {
      // There is extra rule to prevent non-printable character to be filled into the field, but we run sanitise function again for extra safety.
      this.jigMetadata.Tags = this.$refs.tagsChipList.editedItems.map(tagItem => AppHelper.stringSanitiser(tagItem))
    }

    if (this.isOwner || this.canEditJig) {
      if (!this.jigMetadata.Tenants) {
        this.jigMetadata.Tenants = []
      }

      if (this.isCopyableByOthers) {
        this.jigMetadata.CopyableBy = this.copyableBy
      } else {
        this.jigMetadata.CopyableBy = AppConst.JigCopyable.Owner
      }
    }

    if (this.visibilityOnLoad !== this.jigMetadata.ProjectVisibility) {
      // If visibility changes we need to clear our local cache of the branch link because
      // it may change how the link is generated.
      this.clearJigShareLink({
        jigId: this.jigMetadata.Id,
        requestInFlight: false,
        shareURL: '',
      } as JigShareURLData)

      if (this.jigMetadata.ProjectVisibility === AppConst.JigVisibilities.Password) {
        queryParams.regenPassword = regeneratePassword
      } else {
        delete queryParams.regenPassword
      }
    }

    if (this.jigMetadata.Features == null && this.pullApartAllowed || this.jigMetadata.Features && this.pullApartAllowed !== this.jigMetadata.Features.options[AppConst.Features.AVPFeaturePullApartKey].enabled) {
      telemetryRequired = true

      this.jigMetadata.Features.options[AppConst.Features.AVPFeaturePullApartKey].enabled = this.pullApartAllowed
    }

    const metaUpdateResponse = await this.updateJigMetadata(this.jigMetadata, queryParams)

    if (metaUpdateResponse.isSuccess) {
      if (telemetryRequired) {
        segmentEventTracking(
          'PullApartSet',
          {
            ...this.eventTracking,
            origin: 'Dashboard',
            jigId: this.jigMetadata.Id,
            jigName: this.jigMetadata.ProjectName,
            tenantName: this.myTenant.Name,
            tenantId: this.myTenantID,
            state: this.pullApartAllowed,
          }
        )
      }
      this.loadJigAndReset()
    } else if (metaUpdateResponse.status === AppConst.nonSystemErrors.unprocessableEntity.status) {
      this.saveJigError = AppConst.nonSystemErrors.unprocessableEntity.copy
      this.$refs.saveJigErrorPrompt.ShowPrompt()
    }
  }

  private transferJigPrompt() {
    this.$refs.transferJigPromptYesNo.ShowPrompt()
  }

  private changeJigOwnerTenantPrompt() {
    this.$refs.changeJigOwnerTenantPromptYesNo.ShowPrompt()
  }

  private async transferJigPromptConfirmed() {
    if (!this.transferJigPromptIsValid || this.jigMetadata.Id.length === 0) {
      return
    }

    const req: ChangeJigOwnerRequest = {
      JigId: this.jigMetadata.Id,
      DesiredOwnerEmail: this.desiredOwnerEmail,
      DesiredOwnerTenantID: this.transferToTenantID,
      action: 'transfer_ownership',
    }
    if (await this.changeJigOwner(req)) {
      if (this.changeJigOwnerResult.status === 'success') {
        this.$router.push({ path: '/my-jigs' })
      }
    }
  }

  private async changeJigOwnerTenantPromptConfirmed() {
    if (!this.changeJigOwnerTenantPromptIsValid || this.jigMetadata.Id.length === 0) {
      return
    }

    const req: ChangeOwnerTenantRequest = {
      JigId: this.jigMetadata.Id,
      DesiredOwnerTenantID: this.transferToTenantID,
    }

    if (await this.changeJigOwnerTenant(req)) {
      if (this.changeJigOwnerResult.status === 'success') {
        // jigMetadata state will not get updated until next page refresh
        // in order to show updated Team Owner in real time
        // manually set the new OwnerTenantID.
        this.jigMetadata.OwnerTenantID = this.transferToTenantID
      }
    }
  }

  private async uploadThumbnailClick(isPostRequest?: any) {
    const isFirstThumbnail: boolean = isPostRequest != null ? isPostRequest : hasThumbnailPlaceHolder(this.jigMetadata.ThumbnailURL)

    const req: UploadThumbnailRequest = {
      Id: this.jigMetadata.Id,
      TenantId: this.myTenantID,
      Thumbnail: this.jigThumbnailToUpload,
      IsFirstThumbnail: isFirstThumbnail,
      version: this.jigMetadata.Version,
    }

    if (!isFirstThumbnail) {
      req.DateUpdated = this.jigMetadata.DateUpdated
    }
    this.isUploadingThumbnail = true
    const thumbnailUploadResults = await this.uploadJigThumbnail(req)

    if (thumbnailUploadResults.isSuccess) {
      this.loadJigAndReset()
    } else if (thumbnailUploadResults.status === AppConst.nonSystemErrors.conflict.status) {
      // If a client sends a POST request when the server expects a PUT request, the server will respond with a 409 error.
      // Fallback to a PUT request and try again.
      // This most likely will happen to old Jigs.
      await this.uploadThumbnailClick(false)
      return
    } else if (thumbnailUploadResults.status === AppConst.nonSystemErrors.unprocessableEntity.status) {
      this.thumbnailUploadError.isActive = true
      return
    }

    this.isUploadingThumbnail = false
    this.isShowUploadThumb = false
  }

  private async onThumbnailErrorRefreshClick() {
    this.isUploadingThumbnail = false
    this.isShowUploadThumb = false
    await this.reloadData()
    this.thumbnailUploadError.isActive = false
  }

  private async thumbFileInputChange(file: File) {
    // To display a thumbnail preview, we set a v-img src to the FileReader result.
    if (FileReader && file && file.size) {
      const fr = new FileReader()
      fr.onload = () => {
        this.jigThumbnailToUploadArrayBuffer = fr.result
      }
      fr.readAsDataURL(file)
    } else {
      this.jigThumbnailToUploadArrayBuffer = ''
    }
    if (file && file.size) {
      this.jigThumbnailToUpload = file
      this.isThumbFileSelected = true
      this.uploadExternalValidationErrors = []
      this.isThumbnailUploadValid = false
      await ValidationRules.ImageSizeJigThumbnail(file).then(
        (result: ImageValidationResult) => this.onThumbnailValidationComplete(result))
    } else {
      this.isThumbFileSelected = false
    }
  }

  private onThumbnailValidationComplete(result: ImageValidationResult) {
    this.isThumbnailUploadValid = result.valid
    if (!result.valid) {
      this.uploadExternalValidationErrors = [`Thumbnail dimensions must be no larger than
        ${result.xMax} x ${result.yMax}px. Supplied dimensions: ${result.x} x ${result.y}px. `]
    }
  }

  private getUidLabel(): string {
    let msg = this.jigMetadata.Uid
    if (this.jigMetadata.Uid === this.$auth0.uid) {
      msg += ' (You)'
    } else {
      msg += ' (Not you)'
    }
    return msg
  }

  private get shareListData(): string {
    if (this.jigDetailPermissions != null && this.jigDetailPermissions.length > 0) {
      return JSON.stringify(this.jigDetailPermissions)
    } else {
      return 'Not Shared'
    }
  }

  private get jigDisplayName(): string {
    if (this.jigMetadata === null || this.jigMetadata === null) {
      return ''
    }

    if (this.readonlyVue && !this.isLoadingJigDetails) {
      return this.jigMetadata.ProjectName + ' (view only)'
    } else {
      return this.jigMetadata.ProjectName
    }
  }

  onUpdateJigShareConfig(payload: any) {
    this.permissionsDirty = payload.permissionsDirty
  }

  private async showJigPermissionsPrompt() {
    const jigMeta = this.jigMetadata
    const jig: any = {
      id: jigMeta.Id,
      uid: jigMeta.Uid,
      requestUserPermission: jigMeta.RequestUserPermission,
    }

    await this.$nextTick()
    this.$refs.sharePrompt.ShowPrompt([jig])
  }

  private onSharePromptClose(payload: any) {
    // If any request error happened in sharePrompt, reset jig data.
    if (payload.updateResults === false) {
      this.permissionsDirty = true
    }

    this.loadJigIfPermissionsDirty()
  }

  private async reloadData() {
    this.permissionsDirty = true
    await this.loadJigIfPermissionsDirty()
  }

  private async loadJigIfPermissionsDirty() {
    if (this.permissionsDirty) {
      this.permissionsDirty = false
      await this.loadJigAndReset()
    }
  }

  private togglePullApartHint() {
    this.$refs.pullApartHint.toggleHintTooltip()
  }

  private get isSuperUserOrJigStaff(): boolean {
    return TenantHelpers.IsSuperUserOrJigStaff()
  }

  private get publicSharingSwitchDisabled(): boolean {
    return this.readonlyVue || this.isSuperUserOrJigStaff
  }
}
