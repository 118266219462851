export class DateFormatter {
  public static getIndexById(list: any, keyName: any, keyValue: any): number {
    return list.findIndex((x: any) => x[keyName] === keyValue)
  }

  public static formatSubscriptionDate(unixTimestamp: number): string {
    const today = parseInt(((new Date()).valueOf() / 1000).toString(), 10); // Get datetime of now in seconds.
    const timeDifferences = unixTimestamp - today
    const oneDayGap = 86400; // 1 day = 86400 seconds
    const oneWeekGap = 604800; // 7 days = 604800 seconds.
    const unixTimestampMilSec = unixTimestamp * 1000
    const date = new Date(unixTimestampMilSec)
    // Display time in locale time stringi 12hr format
    const dateTime12hr = date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric', hour12: true })
    let dateString = ''

    if (timeDifferences < oneDayGap) {
      // If less than 24hrs, display "Tomorrow at 10:12 AM"
      dateString = `Tomorrow at ${dateTime12hr}`
    } else if (timeDifferences >= oneDayGap && timeDifferences < oneWeekGap) {
      // If more than a day, less than a week, display "Weekday at Time", for example: "Sunday at 2:30 AM"
      dateString = `${date.toLocaleDateString(undefined, { weekday: 'long' })} at ${dateTime12hr}`
    } else {
      // If more than a week, display date in locale date string, for example: "26/08/2021"
      dateString = date.toLocaleDateString()
    }

    return dateString
  }

  public static prefillLeadingZero(digit: Number): string {
    const stringValue = `0${digit}`
    return stringValue.slice(-2)
  }

  public static getDayString(date: Date, short?: Boolean):string {
    switch (date.getDay()) {
      case 0:
        return short ? 'Sun' : 'Sunday'
      case 1:
        return short ? 'Mon' : 'Monday'
      case 2:
        return short ? 'Tue' : 'Tuesday'
      case 3:
        return short ? 'Wed' : 'Wednesday'
      case 4:
        return short ? 'Thu' : 'Thursday'
      case 5:
        return short ? 'Fri' : 'Friday'
      case 6:
        return short ? 'Sat' : 'Saturday'
      default:
        return 'Invalid_Day'
    }
  }

  public static getMonthString(date: Date, short?: Boolean): string {
    switch (date.getMonth()) {
      case 0:
        return short ? 'Jan' : 'January'
      case 1:
        return short ? 'Feb' : 'February'
      case 2:
        return short ? 'Mar' : 'March'
      case 3:
        return short ? 'Apr' : 'April'
      case 4:
        return short ? 'May' : 'May'
      case 5:
        return short ? 'Jun' : 'June'
      case 6:
        return short ? 'Jul' : 'July'
      case 7:
        return short ? 'Aug' : 'August'
      case 8:
        return short ? 'Sep' : 'September'
      case 9:
        return short ? 'Oct' : 'October'
      case 10:
        return short ? 'Nov' : 'November'
      case 11:
        return short ? 'Dec' : 'December'
      default:
        return 'Invalid_Month'
    }
  }
}
